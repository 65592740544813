<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/anhui/39yuan20GB/top.png"
    />
    <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
      />
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <img
        class="two"
        v-if="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
      />
      <img
        class="three"
        v-if="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
      />
      <template>
        <van-field
          v-model="form.phone_code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          :class="['input-code', { 'two-cell': num == 2 }]"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif"
        class="submit"
        @click="onSubmit"
      />
      <img
        class="four"
        v-if="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif"
      />
      <div class="checked">
        <van-checkbox
          checked-color="#fb663e"
          v-model="checked"
          icon-size="18px"
          shape="square"
        >
          <span class="text">我已阅读并同意</span>
          <u class="book">
            <span @click.stop="showDialog('资费说明')">《资费说明》、</span>
            <span @click.stop="showDialog('个人信息保护授权协议')"
              >《隐私政策》</span
            >
          </u>
        </van-checkbox>
      </div>
    </div>
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/anhui/39yuan20GB/info.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/anhui/39yuan20GB/qdbs.webp"
    />
    <!-- 二确 -->
    <BaseDialogConfirm
      :width="692"
      :height="712"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/anhui/39yuan20GB/dialog.png"
      :visibility.sync="visibility"
      closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_close.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/dialog_submit_querendinggou.png"
    />
  </div>
</template>
<script>
import { huHinSendSMS, huHinBuy } from "@/api/qiuMoFusion/index";
import { generateSign } from "@/utils/common";
import tailJumpMixin from "@/utils/tailJump.js";
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";

export default {
  mixins: [tailJumpMixin],
  components: {
    BaseDialogConfirm,
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        phone_number: "",
        phone_code: "",
        response_order_id: "",
        url: "",
        app_name: "",
        product_id: "60901001",
      },
      num: 1,
      visibility: false,
      checked: false,
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/ALL/dx_fusion`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handlerscroll);
    const that = this;
    const phone = that.form.phone_number || "";
    window.onpopstate = function (event) {
       window.location.replace(
        that.tailJumpUrl(that.link, "QIUMORONGHE", phone, "退出")
      );
    };
  },
  created() {
    const { phone = "" } = this.$route.query;
    this.form = {
      ...this.form,
      phone_number: phone,
      url: location.href,
      app_name: navigator.userAgent,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone_number = "", phone_code } = value;
        if (phone_number.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && phone_code.length == 0) {
          this.num = 2;
        } else if (phone_code.length < 6) {
          this.num = 3;
        } else if (phone_code.length == 6 && phone_number.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      const {
        phone_number = "",
        url = "",
        app_name = "",
        product_id = "",
      } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }

      this.yzm_loading = true;
      try {
        const res = await huHinSendSMS({
          product_id,
          phone_number,
          default_app_name: "无",
          default_package_name: "无",
          url,
          app_name,
          auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
        });

        if (res.errorCode === "000") {
          this.form = {
            ...this.form,
            response_order_id: res.response_order_id,
          };
          // 设置倒计时秒。
          this.sendAuthCode = false;
          this.auth_time = 120;
          let auth_timetimer = setInterval(() => {
            this.auth_time--;
            if (this.auth_time <= 0) {
              this.sendAuthCode = true;
              clearInterval(auth_timetimer);
            }
          }, 1000);
        } else {
          this.$dialog.alert({ message: res.errorMsg });
           setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                phone_number,
                "验证码下发失败"
              )
            );
          }, 3000);
        }
      } finally {
        this.yzm_loading = false;
      }
    },
    onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone_number) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.phone_code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.phone_code)) {
        this.$toast.fail("验证码错误");
      } else if (!this.checked) {
        this.$toast.fail("请阅读并勾选协议");
      } else {
        this.visibility = true;
      }
    },
    async handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const { response_order_id = "" } = this.form;
      const res = await huHinBuy({
        ...this.form,
        response_order_id,
        auth: generateSign("QIUMO", "YUVFIUC(*OVO()"),
      });
      this.visibility = false;
      this.$toast({
        duration: 8000,
        message: res.errorMsg,
      });
      if (res.errorCode === "000") {
        setTimeout(() => {
          window.location.replace(
            this.tailJumpUrl(
              this.link,
              `QIUMORONGHE`,
              this.form.phone_number,
              "成功"
            )
          );
        }, 3000);
      } else {
        setTimeout(() => {
          window.location.replace(
            this.tailJumpUrl(
              this.link,
              `QIUMORONGHE`,
              this.form.phone_number,
              "失败"
            )
          );
        }, 3000);
      }
    },
    showDialog(title) {
      const data = {
        个人信息保护授权协议: `尊敬的客户：
您好！
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
（1）事先获得客户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关司法机关和/或政府主管部门的要求；
（4）为维护社会公众的利益所必需且适当；
（5）为维护我公司或客户的合法权益所必需且适当。`,
        资费说明: `产品说明
一适用范围：安徽电信后付费新、老用户（预付费套餐、时长卡、红包卡用户无法订购）; 
二、产品资费及使用规则：
1.月基本费：39元（账单支付）含20GB国内通用流量；
2.包内流量主副卡可共享，可提速，当月剩余流量可结转；如当月退订此包，则当月剩余流量不结转至次月； 
3.未被降速的用户订购后，等同于增加降速阈值；已被降速用户订购后，恢复速率，包内流量使用完后再次降速。非达量降速套餐订购等同于普通流量； 
4.此流量包不能抵扣已经产生的套餐外流量费用； 
5.流量抵扣顺序：定向流量包、畅享视频流量包、闲时流量包、短期流量包、畅聊流量包、后向全国流量月包、流量加餐包、包月流量包、赠送全国流量、畅享提速包、月末流量包、5G升级包流量、大流量月包、套餐内全国流量、后向全国流量季包、季/半年/年包流量、畅享升级包，超出后执行套外流量资费；
三、生失效及退订业务规则 
1.订购规则：有效期内同一个号码最多可订购5个，主副卡均可订购； 
2.生效规则：订购立即生效，默认按月自动续订； 
3.退订次月生效；
四、其他提醒包内流量为国内流量，适用于中国电信移动通信网络，限在中国大陆境内（不含港澳台）使用，不含WLAN（Wi-Fi）上网。`,
      };
      this.$dialog.alert({
        messageAlign: "left",
        title: title,
        message: data[title],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .img {
    width: 100%;
  }

  .form {
    position: relative;
    background-image: url("https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/DX/zhejiang/specialOffer29/bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 90px 0 60px;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 156px;
      right: 40px;
      top: 138px;
    }

    .three {
      width: 430px;
      transform: translate(80px, -30px);
    }

    .four {
      width: 310px;
      transform: translate(200px, -76px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 30px;

        .code {
          width: 229px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #ff7900;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #ff7900;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 640px;
      display: block;
      margin: 40px auto 0;
      padding-left: 10px;
      animation: identifier 2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
      }
    }

    .checked {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 20px 0 0;

      ::v-deep .van-checkbox {
        width: auto;

        &__icon {
          border: 1px solid #fff;
          .van-icon {
            border: none;
          }
        }

        &__label {
          font-size: 30px;
          color: #fff;
        }
      }

      .book {
        text-align: right;
        font-weight: 400;
        // color: #f8f107;
      }
    }
  }
  ::v-deep .dialog-confirm {
    .footer {
      padding-top: 590px;
      align-items: center;

      .btn-close {
        width: 180px;
      }

      .btn-submit {
        height: 72px;
        width: 215px;
      }
    }
  }
}
</style>

